class Shim {
  private iframe = document.createElement('iframe');
  private shopToken = '';
  private customBgColor = '';
  private formOpened = false;
  private dockLeft = false;
  private bottomOffset = 0;
  private locations = null;

  init = (
    shopToken: string,
    customBgColor: string,
    formOpened: boolean,
    dockLeft: boolean,
    bottomOffset: number,
    locations: any
  ) => {
    this.shopToken = shopToken;
    this.customBgColor = customBgColor;
    this.formOpened = formOpened;
    this.dockLeft = dockLeft;
    this.bottomOffset = bottomOffset;
    this.locations = locations;
    this.mountIframe();
  };

  receiveMessage = (event: MessageEvent) => {
    if (!!event && !!event.data && !!event.data.type) {
      switch (event.data.type) {
        case 'MINIMIZE_WIDGET':
          this.toggleWidgetSize(event.data.value);
          break;
        case 'OPEN_WIDGET':
          this.toggleWidgetSize(false);
          this.iframe.contentWindow!.postMessage(
            {
              type: 'OPEN_WIDGET',
              message_body: event.data.message_body,
            },
            '*'
          );
          break;
        case 'CURRENT_ORIGIN':
          this.iframe.contentWindow!.postMessage(
            {
              type: 'CURRENT_ORIGIN',
              origin: document.location.href,
            },
            '*'
          );
      }
    }
  };

  toggleWidgetSize = (minimized: boolean) => {
    const wrapper = document.getElementById('ikeono-wrapper');
    if (wrapper) {
      this.resizeIframeContainer(wrapper, minimized);
    }
  };

  resizeIframeContainer = (wrapper: HTMLElement, minimized: boolean) => {
    if (minimized) {
      wrapper.style.height = '110px';
      wrapper.style.width = '210px';
      wrapper.style.transitionDelay = '1s';
      wrapper.style.transitionProperty = 'width, height';
    } else {
      wrapper.style.height = '540px';
      wrapper.style.width = '320px';
      wrapper.style.transitionDelay = '0s';
    }
  };

  mountIframe = () => {
    if (this.formOpened) {
      const alreadyOpened = sessionStorage.getItem('autoOpened');
      if (!alreadyOpened) {
        sessionStorage.setItem('autoOpened', true);
      } else {
        this.formOpened = false;
      }
    }
    this.iframe.onload = () => {
      this.iframe.contentWindow!.postMessage(
        {
          type: 'INIT_IFRAME',
          shopToken: this.shopToken,
          customBgColor: this.customBgColor,
          formOpened: this.formOpened,
          dockLeft: this.dockLeft,
          bottomOffset: this.bottomOffset,
          locations: this.locations,
        },
        '*'
      );
    };

    this.iframe.src = 'https://ikeono-widget.netlify.app/';
    this.iframe.style.border = '0';
    this.iframe.style.width = '100%';
    this.iframe.style.height = '100%';
    this.iframe.setAttribute('title', 'Webchat Widget');
    this.iframe.setAttribute('id', 'ikeono_webchat_widget');
    const wrapper = document.createElement('div');
    wrapper.setAttribute('id', 'ikeono-wrapper');
    wrapper.appendChild(this.iframe);
    wrapper.style.position = 'fixed';
    if (this.bottomOffset) {
      wrapper.style.bottom = `${this.bottomOffset.toString()}px`;
    } else {
      wrapper.style.bottom = '0';
    }
    if (this.dockLeft) {
      wrapper.style.left = '0';
    } else {
      wrapper.style.right = '0';
    }
    wrapper.style.height = '110px';
    wrapper.style.width = '210px';
    wrapper.style.zIndex = '2147483647';
    document.body.appendChild(wrapper);
    window.addEventListener('message', this.receiveMessage, false);
  };
}

export default ((window) => {
  const stubSdk = window.ikeono;
  const initCall = stubSdk._beforeLoadCallQueue.find(
    (call: string[]) => call[0] === 'init'
  );
  // TODO: this is getting ugly :(
  const token = initCall[1][0];
  const customColor = initCall[1][1];
  const formOpened = initCall[1][2];
  const dockLeft = initCall[1][3];
  const locations = initCall[1][5];

  let bottomOffset = 0;
  if (initCall[1].length >= 5) {
    bottomOffset = initCall[1][4];
  }

  const shim = new Shim();
  stubSdk.init = shim.init;
  initCall &&
    shim.init(
      token,
      customColor,
      formOpened,
      dockLeft,
      bottomOffset,
      locations
    );
})(global);
